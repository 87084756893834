import React from "react";
import PointIcon from "../../../assets/images/vector/components/common/group-copy.svg";
import { PAGES } from "../../../helpers/constants";
import { graphql, useStaticQuery } from "gatsby";
import StandardFormBlockContainer from "../../../containers/common/forms/StandardFormBlockContainer";
import FastImage from "../../../containers/common/FastImage";
import PG_SS_HOME_INTRO_CONTENT from "./index.content";
import "./index.scss";

const HomeIntro = () => {
  const { itemList } = PG_SS_HOME_INTRO_CONTENT;

  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          name: { regex: "/SSHomeIntroView-screen/" }
          ext: { eq: ".jpg" }
        }
      ) {
        edges {
          node {
            id
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                blurredOptions: { toFormat: AUTO }
                jpgOptions: { progressive: false }
              )
            }
            name
          }
        }
      }
    }
  `);

  return (
    <section className="PgSS-HomeIntro">
      <div className="container">
        <div className="PgSS-HomeIntro-row">
          <div className="PgSS-HomeIntro__info">
            <h1 className="PgSS-HomeIntro__title">AMZScout Stock Stats</h1>
            <p className="PgSS-HomeIntro__subTitle">
              Check Out the Inventory Level of Your Amazon Competitors
            </p>
            <ul className="PgSS-HomeIntro__points PgSS-HomeIntro-points">
              {itemList.map((text, i) => (
                <li key={i} className="PgSS-HomeIntro-points__item">
                  <img src={PointIcon} alt="" />
                  <p>{text}</p>
                </li>
              ))}
            </ul>
            <StandardFormBlockContainer
              buttonTextFirstState="Try Stock Stats For Free!"
              buttonTextSecondState="Try Stock Stats For Free!"
              page={PAGES.STOCK_STATS}
              customClass="PgSS-HomeIntro-emailCollect"
              placeholder="Enter your email to start your FREE TRIAL!"
              isIntro
            />
          </div>
          <div className="PgSS-HomeIntro-screen">
            <FastImage
              imageData={data}
              wrapImageClassName="PgSS-HomeIntro__img"
              imageAltName="home image"
              isAdaptive
              isWithRatio
              defaultName="SSHomeIntroView-screen"
            />
            <div className="PgSS-HomeIntro__border" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeIntro;
