import React, { useContext, useRef } from "react";
import { MediaAuthContext } from "../store/context/media-auth-context";
import Layout from "../layouts/LayoutDefault";
import Seo from "../components/Seo";
import OgImg from "../assets/images/raster/pages/stock-stats/common/stock_mainscreen@2x.png";
import PG_SS_RATING_REVIEW_CONTENT from "../components/stock-stats/RatingReview/index.content";
import { PAGES } from "../helpers/constants";
import HomeIntro from "../components/stock-stats/HomeIntro";
import ChromeRating from "../components/stock-stats/ChromeRating";
import PartnersBrand from "../components/stock-stats/PartnersBrand";
import Pricing from "../components/stock-stats/Pricing";
import StandardFormBlockContainer from "../containers/common/forms/StandardFormBlockContainer";
import ProInstall from "../components/stock-stats/ProInstall";
import Spy from "../components/stock-stats/Spy";
import RatingReview from "../components/stock-stats/RatingReview";
import "../components/stock-stats/index.scss";

const PgSS = () => {
  const {
    auth: { isAuth },
  } = useContext(MediaAuthContext);
  const pricingRef = useRef(null);
  const { postData } = PG_SS_RATING_REVIEW_CONTENT;

  return (
    <Layout tariffsElement={pricingRef} customClass="PgSS">
      <Seo
        title="Spy Amazon Stocks - Inventory Management | AMZScout"
        description="Freeware. Spy on competitors sales without leaving the Amazon product page. Plan sales accordingly."
        page="stock-stats"
        manifest="browserconfig.xml"
        ogImg={OgImg}
      />
      <HomeIntro />
      <ChromeRating />
      <PartnersBrand />
      <RatingReview excellentText="Excellent" posts={postData} />
      <Spy />
      <Pricing
        pricingRef={pricingRef}
        sectionTitle="Stock Stats is now part of the Amazon Sellers Bundle by AMZScout!"
      />
      <ProInstall />
      <StandardFormBlockContainer
        buttonTextFirstState="Try Stock Stats For Free!"
        buttonTextSecondState="Try Stock Stats For Free!"
        page={PAGES.STOCK_STATS}
        customClass={
          isAuth
            ? "PgSS-standardFormPageBlock-secondState"
            : "PgSS-standardFormPageBlock-lastForm"
        }
        title={
          "Get all of the data about competitors' inventories and sales with Stock Stats FREE TRIAL!"
        }
      />
    </Layout>
  );
};

export default PgSS;
