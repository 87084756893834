import React from "react";
import StarIcon from "../../../assets/images/vector/components/ChromeRating/new_star.svg";
import HalfStarIcon from "../../../assets/images/vector/components/ChromeRating/new_star_half.svg";
import "./index.scss";

const ChromeRating = () => {
  return (
    <div className="PgSS-ChromeRating">
      <div className="container">
        <div className="PgSS-ChromeRating__wrapper">
          <div className="PgSS-ChromeRating__stars">
            <img src={StarIcon} alt="" loading="lazy" />
            <img src={StarIcon} alt="" loading="lazy" />
            <img src={StarIcon} alt="" loading="lazy" />
            <img src={StarIcon} alt="" loading="lazy" />
            <img src={HalfStarIcon} alt="" loading="lazy" />
          </div>
          <span className="PgSS-ChromeRating__note">
            Chrome Store Rating | 500,000 users
          </span>
          <span className="PgSS-ChromeRating__info">
            over 5 million profitable products found
          </span>
        </div>
      </div>
    </div>
  );
};

export default ChromeRating;
