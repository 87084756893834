import React from "react";
import PropTypes from "prop-types";
import CustomizableContainer from "../../../containers/Paywalls/Customizable/CustomizableContainer";
import "./index.scss";

const Pricing = ({ pricingRef, id, title, sectionTitle }) => {
  return (
    <section className="PgSS-Pricing" id={id}>
      <h2 className="PgSS-Pricing__sectionTitle">{sectionTitle}</h2>
      <CustomizableContainer pricingRef={pricingRef} title={title} subTitlePro/>
    </section>
  );
};
Pricing.propTypes = {
  pricingRef: PropTypes.any.isRequired,
  id: PropTypes.string,
  title: PropTypes.string,
};

export default Pricing;
