import React from "react"

const PG_SS_HOME_INTRO_CONTENT = {
  itemList: [
    "Learn a competitor’s stock, sales, ratings, and number of sellers",
    "Analyze the data without ever leaving the Amazon product page",
  ],
}

export default PG_SS_HOME_INTRO_CONTENT
