import React from "react";
import PG_SS_PARTNERS_BRAND_CONTENT from "./index.content";
import "./index.scss";

const PartnersBrand = () => {
  const { dataList } = PG_SS_PARTNERS_BRAND_CONTENT;

  return (
    <div className="PgSS-PartnersBrand">
      <div className="container">
        <div className="PgSS-PartnersBrand__wrapper">
          {dataList.map(({ name, icon }, i) => (
            <div key={i}>
              <img
                className={`PgSS-PartnersBrand__${name} PgSS-PartnersBrand__img`}
                src={icon}
                alt=""
                loading="lazy"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PartnersBrand;
