import React from "react"
import HpIcon from "../../../assets/images/vector/components/PartnerBrand/hp-new.svg"
import AlibabaIcon from "../../../assets/images/vector/components/PartnerBrand/alibaba-new.svg"
import RedbullIcon from "../../../assets/images/vector/components/PartnerBrand/redbull-new.svg"
import DisneyIcon from "../../../assets/images/vector/components/PartnerBrand/disney-new.svg"
import DellIcon from "../../../assets/images/vector/components/PartnerBrand/dell-new.svg"
import CasioIcon from "../../../assets/images/vector/components/PartnerBrand/casio-new.svg"

const PG_SS_PARTNERS_BRAND_CONTENT = {
  dataList: [
    { name: "hp", icon: HpIcon },
    { name: "alibaba", icon: AlibabaIcon },
    { name: "redbull", icon: RedbullIcon },
    { name: "disney", icon: DisneyIcon },
    { name: "dell", icon: DellIcon },
    { name: "casio", icon: CasioIcon },
  ],
}

export default PG_SS_PARTNERS_BRAND_CONTENT
