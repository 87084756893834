import React from "react"

import deskImgOneWebp2x from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-1@2x.webp"
import deskImgOneWebp from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-1.webp"
import deskImgOneJpg2x from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-1@2x.jpg"
import deskImgOneJpg from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-1.jpg"
import mobImgOneWebp2x from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-1@mob2x.webp"
import mobImgOneWebp from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-1@mob.webp"
import mobImgOneJpg2x from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-1@mob2x.jpg"
import mobImgOneJpg from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-1@mob.jpg"

import deskImgTwoWebp2x from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-2@2x.webp"
import deskImgTwoWebp from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-2.webp"
import deskImgTwoJpg2x from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-2@2x.jpg"
import deskImgTwoJpg from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-2.jpg"
import mobImgTwoWebp2x from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-2@mob2x.webp"
import mobImgTwoWebp from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-2@mob.webp"
import mobImgTwoJpg2x from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-2@mob2x.jpg"
import mobImgTwoJpg from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-2@mob.jpg"

import deskImgThreeWebp2x from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-3@2x.webp"
import deskImgThreeWebp from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-3.webp"
import deskImgThreeJpg2x from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-3@2x.jpg"
import deskImgThreeJpg from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-3.jpg"
import mobImgThreeWebp2x from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-3@mob2x.webp"
import mobImgThreeWebp from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-3@mob.webp"
import mobImgThreeJpg2x from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-3@mob2x.jpg"
import mobImgThreeJpg from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-3@mob.jpg"

import deskImgFourWebp2x from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-4@2x.webp"
import deskImgFourWebp from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-4.webp"
import deskImgFourJpg2x from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-4@2x.jpg"
import deskImgFourJpg from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-4.jpg"
import mobImgFourWebp2x from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-4@mob2x.webp"
import mobImgFourWebp from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-4@mob.webp"
import mobImgFourJpg2x from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-4@mob2x.jpg"
import mobImgFourJpg from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-4@mob.jpg"

import deskImgFiveWebp2x from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-5@2x.webp"
import deskImgFiveWebp from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-5.webp"
import deskImgFiveJpg2x from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-5@2x.jpg"
import deskImgFiveJpg from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-5.jpg"
import mobImgFiveWebp2x from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-5@mob2x.webp"
import mobImgFiveWebp from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-5@mob.webp"
import mobImgFiveJpg2x from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-5@mob2x.jpg"
import mobImgFiveJpg from "../../../assets/images/raster/pages/stock-stats/Spy/SSSpyView_screenshot-5@mob.jpg"

const PG_SS_SPY_CONTENT = {
  BSR: {
    title: "1. Check a Product’s Best Sellers Rank (BSR)",
    paragraphText:
      "A BSR is a number that nearly every item on Amazon has. This parameter is awarded once the item has had at least 1 sale. By analyzing BSR changes on a daily basis, it’s possible to estimate if the product sales are going up or down.",
    screenshot: {
      deskImgWebp2x: deskImgOneWebp2x,
      deskImgWebp: deskImgOneWebp,
      deskImgJpg2x: deskImgOneJpg2x,
      deskImgJpg: deskImgOneJpg,
      mobImgWebp2x: mobImgOneWebp2x,
      mobImgWebp: mobImgOneWebp,
      mobImgJpg2x: mobImgOneJpg2x,
      mobImgJpg: mobImgOneJpg,
    },
  },
  levelAnalysis: {
    title: "2. Analyze Total and Average Stock Levels",
    paragraphText:
      "Inventory is the product stock you or your competitors own to sell through a business on Amazon. If there were 100 items available yesterday and today there are only 60, it would be fair to assume 40 items have sold. It is fantastic data to determine pricing strategy or to make purchasing decisions.",
    screenshot: {
      deskImgWebp2x: deskImgTwoWebp2x,
      deskImgWebp: deskImgTwoWebp,
      deskImgJpg2x: deskImgTwoJpg2x,
      deskImgJpg: deskImgTwoJpg,
      mobImgWebp2x: mobImgTwoWebp2x,
      mobImgWebp: mobImgTwoWebp,
      mobImgJpg2x: mobImgTwoJpg2x,
      mobImgJpg: mobImgTwoJpg,
    },
  },
  sellerResearch: {
    title: "3. Research Every Seller Selling the Same Product",
    paragraphText: [
      "Find out the numbers of potential competitors",
      "Use the feature to determine the FBA and MF sellers",
      "Determine the selling price of the product in a niche",
      "Evaluate the quality and quantity of ratings for each product in the last 12 months",
    ],
    firstScreenshot: {
      deskImgWebp2x: deskImgThreeWebp2x,
      deskImgWebp: deskImgThreeWebp,
      deskImgJpg2x: deskImgThreeJpg2x,
      deskImgJpg: deskImgThreeJpg,
      mobImgWebp2x: mobImgThreeWebp2x,
      mobImgWebp: mobImgThreeWebp,
      mobImgJpg2x: mobImgThreeJpg2x,
      mobImgJpg: mobImgThreeJpg,
    },
    secondScreenshot: {
      deskImgWebp2x: deskImgFourWebp2x,
      deskImgWebp: deskImgFourWebp,
      deskImgJpg2x: deskImgFourJpg2x,
      deskImgJpg: deskImgFourJpg,
      mobImgWebp2x: mobImgFourWebp2x,
      mobImgWebp: mobImgFourWebp,
      mobImgJpg2x: mobImgFourJpg2x,
      mobImgJpg: mobImgFourJpg,
    },
    thirdScreenshot: {
      deskImgWebp2x: deskImgFiveWebp2x,
      deskImgWebp: deskImgFiveWebp,
      deskImgJpg2x: deskImgFiveJpg2x,
      deskImgJpg: deskImgFiveJpg,
      mobImgWebp2x: mobImgFiveWebp2x,
      mobImgWebp: mobImgFiveWebp,
      mobImgJpg2x: mobImgFiveJpg2x,
      mobImgJpg: mobImgFiveJpg,
    },
  },
}

export default PG_SS_SPY_CONTENT
