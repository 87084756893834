import React from "react";
import SvgRating from "../../../assets/images/vector/components/RatingReview/RatingReviewTrust.svg";

const RatingReviewPost = ({ title, description, descriptionLong }) => (
  <div key={title} className="PgSS-RatingReview-post">
    <img src={SvgRating} alt="" className="PgSS-RatingReview-post__stars" />
    <p className="PgSS-RatingReview-post__title">{title}</p>
    <div className="PgSS-RatingReview-post__info">
      {description()}
      {descriptionLong ? (
        <div className="PgSS-RatingReview__link">read more</div>
      ) : null}
    </div>
  </div>
);

export default RatingReviewPost;
