import React from "react"

const PG_SS_RATING_REVIEW_CONTENT = {
  postData: [
    {
      title: "It is very accurate and fast…",
      description: () =>
        "It is very accurate and fast. Thank you very much AMZScout for providing this high-quality tool to our Amazon seller community without any charge.",
    },
    {
      title: "Absolutely loved the extension…",
      description: () =>
        "Absolutely loved the extension. It gives a fairly accurate picture of the competitors' stock level, which has helped me better understand who I am against and what action should be taken.",
    },
    {
      title: "The tool is great…",
      description: () =>
        "The tool is great for gauging the number of sellers on a listing and determining how much product to purchase.",
    },
  ],
}

export default PG_SS_RATING_REVIEW_CONTENT
