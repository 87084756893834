import React from "react";
import Screenshot from "../Screenshot";
import ListWithTitle from "../ListWithTitle";
import PG_SS_SPY_CONTENT from "./index.content";
import "./index.scss";

const Spy = () => {
  const { BSR, levelAnalysis, sellerResearch } = PG_SS_SPY_CONTENT;

  return (
    <section className="PgSS-Spy">
      <div className="container">
        <h2 className="PgSS-Spy__title">
          Spy on Your Competition with AMZScout Stock Stats
        </h2>
        <div className="PgSS-Spy-row PgSS-Spy-rowOne">
          <div className="PgSS-Spy-BSR">
            <h3 className="PgSS-Spy-BSR__title">{BSR.title}</h3>
            <p>{BSR.paragraphText}</p>
          </div>
          <Screenshot
            classes="PgSS-Spy-rowOne__screenshot"
            listPictures={BSR.screenshot}
          />
        </div>
        <div className="PgSS-Spy__line" />
        <div className="PgSS-Spy-row PgSS-Spy-rowTwo">
          <Screenshot
            classes="PgSS-Spy-rowTwo__screenshot"
            listPictures={levelAnalysis.screenshot}
          />
          <div className="PgSS-Spy-levelAnalysis">
            <h3 className="PgSS-Spy-levelAnalysis__title">
              {levelAnalysis.title}
            </h3>
            <p>{levelAnalysis.paragraphText}</p>
          </div>
        </div>
        <div className="PgSS-Spy__line" />
        <div className="PgSS-Spy-row PgSS-Spy-rowThree">
          <ListWithTitle
            list={sellerResearch.paragraphText}
            classes="PgSS-Spy-sellerResearch"
            title={sellerResearch.title}
          />
          <div className="PgSS-Spy__wrapperPictures">
            <Screenshot
              classes="PgSS-Spy-rowThree__firstScreenshot"
              listPictures={sellerResearch.firstScreenshot}
            />
            <div className="PgSS-Spy-rowThree__wrapSecondScreenshot">
              <div className="PgSS-Spy__inner">
                <Screenshot
                  classes="PgSS-Spy-rowThree__secondScreenshot"
                  listPictures={sellerResearch.secondScreenshot}
                />
                <div className="PgSS-Spy__border" />
              </div>
            </div>
            <div className="PgSS-Spy-rowThree__wrapThirdScreenshot">
              <div className="PgSS-Spy__inner">
                <Screenshot
                  classes="SSSpyView-rowThree__thirdScreenshot"
                  listPictures={sellerResearch.thirdScreenshot}
                />
                <div className="PgSS-Spy__border" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Spy;
